<template>
  <!-- <div class="claim_apply"> -->
  <div class="page_wrapper">
    <div class="fix_head">
      <a href="#" @click="$router.back()">&lt; 返回</a>
      理赔申请
    </div>
    <p class="title">客户基本信息</p>
    <van-cell-group>
      <van-cell title="车主姓名" :value="dataSource.customerName" />
      <van-cell title="联系方式" :value="dataSource.contactPhone" />
      <van-cell title="身份证号" :value="dataSource.certificateNo" />
      <van-cell title="产品名称" :value="dataSource.productName" />
      <van-cell title="服务凭证" :value="dataSource.insuranceNo" />
    </van-cell-group>
    <van-form @submit="onSubmit">
      <p class="title">理赔信息</p>
      <Jselect label="理赔范围" v-model="formData.claimsItemsId" name="claimsItemsId" placeholder="请选择选择理赔范围"
        :options="claimScopeOptions" :rules="[{ required: true, message: ' ' }]" @change="claimsItemsChange"
        class="required"></Jselect>
      <template v-for="item in claimsItem.claimsPaymentMethod">
        <van-field v-if="item.fieldType === 'text'" :key="item.id" v-model="formData[item.fieldCode]"
          :name="item.fieldCode" :label="item.fieldLabel" :placeholder="`请填写${item.fieldLabel}`"
          :rules="[{ required: !!item.required, message: ' ' }]"
          @change="inputChange(item.fieldCode, formData[item.fieldCode])" :class="item.required && 'required'" />
        <Jselect v-else-if="item.fieldType === 'select'" :key="item.id" :label="item.fieldLabel"
          v-model="formData[item.fieldCode]" :name="item.fieldCode" :placeholder="`请选择${item.fieldLabel}`"
          :options="item.options" :rules="[{ required: item.required, message: ' ' }]"
          :class="item.required && 'required'">
        </Jselect>
      </template>
      <!-- <van-field v-model="formData.bankCardNumber" name="银行卡号" label="银行卡号" placeholder="请填写银行卡号"
        :rules="[{ required: true, message: ' ' }]" />
      <Jselect label="开卡银行" v-model="formData.cardIssuingBank" name="开卡银行" placeholder="请选择开卡银行"
        :options="cardIssuingBankOptions" :rules="[{ required: true, message: ' ' }]">
      </Jselect> -->
      <!-- <van-field v-model="formData.cardIssuingBankBranch" name="开卡支行" label="开卡支行" placeholder="请填写开卡支行"
        :rules="[{ required: true, message: ' ' }]" /> -->
      <p class="title">资料上传</p>
      <div style="display: flex; flex-wrap: wrap;">
        <div v-for="item in claimsItem.claimsDocuments" :key="item.id" class="upload">
          <JUpload :label="item.fieldLabel" :name="item.fieldCode" :rules="[{ required: !!item.required, message: ' ' }]"
            v-model="formData[item.fieldCode]" @change="uploadChange($event, item.fieldCode)" :uploadImg="uploadImg"
            :class="item.required && 'required'" />
          <p class="error_tip" v-if="item.annotation">
            {{ item.annotation }}
            <!-- <span v-for="i in 30" :key="i"></span> -->
          </p>
        </div>
      </div>

      <!-- <van-field label="文件上传" name="文件上传" :rules="[{ required: true, message: ' ' }]">
        <template #input>
          <van-uploader v-model="formData.uploader1" />
        </template>
      </van-field> -->
      <div style="margin: 16px;" v-if="!formData.status || formData.status === 5" class="div_btn">
        <!-- <van-button round block type="primary" native-type="submit">
          提交
        </van-button> -->
        <van-button class="query" round block type="info" native-type="submit">提交</van-button>
      </div>
    </van-form>
    <div style="margin: 16px;" v-if="formData.status === 1" class="div_btn">
      <van-button class="query" round block type="info" native-type="submit" @click="onCancel">取消审核</van-button>
      <!-- <van-button round block type="primary">
        取消审核
      </van-button> -->
    </div>
  </div>
  <!-- </div> -->
</template>
<script>
import Jselect from '@/components/Jselect/index.vue'
import JUpload from '@/components/JUpload/index.vue'
import { uploadImg, getSelectBankCode, queryCardBin } from '@/api/sign.js'
import { insuranceClaimsConfig, claimsApplySave, claimsApplyCancel, claimsApplyFind, claimsApplySaveOpenId } from '@/api/verification'
const appid = 'wx2a261eec63d21041' // 'wxadc561be34667a1c'
// const claimScopeOptions = [
//   { text: '10%', value: 1 },
//   { text: '10-30%', value: 2 },
//   { text: '30%-全损', value: 3 },
// ]
const cardIssuingBankOptions = []
export default {
  components: {
    Jselect,
    JUpload
  },
  data() {
    return {
      formData: {
        claimsItemsId: '', // 理赔范围
        // bankCardNumber: '', // 银行卡号
        // cardIssuingBank: '', // 开卡银行
        // cardIssuingBankBranch: '', // 开卡支行
        // uploader: '',
        // uploader1: [],
      },
      dataSource: {},
      bankList: [],
      claimScopeOptions: [],
      claimsItem: {},
      insuranceNo: '',
      cardIssuingBankOptions,
      uploadImg
    }
  },
  created() {
    this.insuranceNo = this.$route.query.keyword || ''

    console.log(window.location)
    // 在连接上获取code，若有则传递到服务端
    const str = window.location.search
    if (str && str.indexOf('code=') != -1) {
      this.code = str.slice(str.indexOf('=') + 1, str.indexOf('&'))
      if (this.code) {
        this.sendCode()
      }
    }
    if (this.insuranceNo) {
      this.insuranceClaimsConfigFun()
    }
  },
  methods: {
    sendCode() {
      console.log(this.code, '===console.log(this.code)')
      claimsApplySaveOpenId({
        insuranceNo: this.insuranceNo,
        code: this.code
      })
      // 发送code到服务端
    },
    getCode() {
      const redirect_uri = window.location.href // 'https://m.juruiyun.com/#/' // window.location.href
      // console.log(redirect_uri, '===redirect_uri')
      // encodeURIComponent(redirect_uri)
      // window.open(`https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appid}&redirect_uri=${encodeURIComponent(redirect_uri)}&response_type=code&scope=snsapi_base#wechat_redirect`)
      window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appid}&redirect_uri=${encodeURIComponent(redirect_uri)}&response_type=code&scope=snsapi_base#wechat_redirect`
      // console.log('====')

      // window.open('https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx2a261eec63d21041&redirect_uri=https%3A%2F%2Fm.juruiyun.com%2F%23%2Fverfication%2Findex%3FisClaim%3D1&response_type=code&scope=snsapi_base#wechat_redirect')
    },
    claimsApplyFindFun() {
      claimsApplyFind({ insuranceNo: this.insuranceNo }).then(res => {
        if (res.success) {
          // console.log(res.content)
          const info = res.content
          if (info) {
            // console.log(info, '==---')
            this.formData.id = info.id
            this.formData.status = info.status
            this.formData.claimsItemsId = info.claimsItemsId
            info.claimsDocuments && info.claimsDocuments.forEach(r => {
              // console.log(r.fieldCode, r.fieldValue, '==')
              // this.formData[r.fieldCode] = r.fieldValue
              this.$set(this.formData, r.fieldCode, r.fieldValue)
            })
            info.claimsPaymentMethod && info.claimsPaymentMethod.forEach(r => {
              // console.log(r.fieldCode, r.fieldValue, '===')
              // this.formData[r.fieldCode] = r.fieldValue
              this.$set(this.formData, r.fieldCode, r.fieldValue)
            })
            // console.log(this.formData)
          } else {
            // 在没有获取到理赔信息的时候，检查路径上是否有code，若没有则获取code
            if (!this.code) {
              this.getCode()
            }
          }
          this.claimsItemsChange()
          // console.log(this.formData, '====this.formData')
        }
      })
    },
    async getBank() {
      const res = await getSelectBankCode()
      return res.success ? res.content.map(r => ({ 'text': r.label, 'value': r.value })) : []
    },
    async inputChange(code, value) {
      if (!value) return
      if (code == 'bankCardNumber') {
        const res = await queryCardBin({ bankCardNo: value })
        if (!res.success) {
          this.$toast(res.respMag)
          return
        }
        this.$set(this.formData, 'issuingBank', res.content.bankCode)
        // this.formData.issuingBank = res.content.bankCode
      }
    },
    claimsItemsChange() {
      this.claimsItem = this.dataSource.claimsItems.filter(res => {
        return res.id === this.formData.claimsItemsId
      })[0]
      this.claimsItem.claimsPaymentMethod?.forEach(async res => {
        // console.log(res)
        if (res.fieldCode == "issuingBank") {
          // res.options = await this.getBank()
          this.$set(res, 'options', await this.getBank())
        }
      });
    },
    insuranceClaimsConfigFun() {
      insuranceClaimsConfig({ insuranceNo: this.insuranceNo }).then(res => {
        if (res.success) {
          this.dataSource = res.content
          this.claimScopeOptions = this.dataSource.claimsItems.map(r => {
            return {
              text: r.claimsItemsName,
              value: r.id,
            }
          })
          this.formData.claimsItemsId = this.dataSource.claimsItems[0].id // 设置理赔范围默认第一个
          this.claimsApplyFindFun()
        }
      })
    },
    uploadChange() {
      // file.status = 'uploading';
      // file.message = '上传中...';
      // let fd = new FormData()
      // fd.append('file', file.file)
      // uploadImg(file).then((res) => {
      //   console.log(res,'====')
      // })
    },
    onSubmit(values) {
      console.log(values, '=====', this.formData)
      // // 理赔信息
      // console.log(this.claimsItem, '====claimsItem')
      let claimsDocuments = this.claimsItem.claimsDocuments.map(res => {
        return {
          fieldCode: res.fieldCode,
          fieldValue: this.formData[res.fieldCode] || ''
        }
      })
      let claimsPaymentMethod = this.claimsItem.claimsPaymentMethod?.map(res => {
        return {
          fieldCode: res.fieldCode,
          fieldValue: this.formData[res.fieldCode] || ''
        }
      })
      const params = {
        id: this.formData.id || '',   // 修改时需要传递id
        insuranceNo: this.dataSource.insuranceNo,  // 保单凭证号
        claimsItemsId: this.claimsItem.id,
        claimsDocuments,
        claimsPaymentMethod
      }
      // console.log(params, '==params', JSON.stringify(params))
      claimsApplySave(params).then(res => {
        if (res.success) {
          this.$message.success(res.respMag);
          setTimeout(() => {
            this.back()
          }, 1000)
        } else {
          this.$message.info(res.respMag);
        }
      })
    },
    back() {
      if (typeof WeixinJSBridge === 'undefined') {
        // 如果 WeixinJSBridge 对象未定义，则每隔1毫秒检查一次，直至其被成功定义后再调用其方法
        if (document.addEventListener) {
          document.addEventListener('WeixinJSBridgeReady', onBridgeReady, false);
        } else if (document.attachEvent) {
          document.attachEvent('WeixinJSBridgeReady', onBridgeReady);
          document.attachEvent('onWeixinJSBridgeReady', onBridgeReady);
        }
        function onBridgeReady() {
          if (typeof WeixinJSBridge !== 'undefined') {
            WeixinJSBridge.call('closeWindow');
          }
        }
      } else {
        // 如果 WeixinJSBridge 对象已经被成功定义，则直接调用其方法
        WeixinJSBridge.call('closeWindow');
      }
    },
    onCancel() {
      claimsApplyCancel({ id: this.formData.id }).then(res => {
        if (res.success) {
          this.$message.success(res.respMag);
          setTimeout(() => {
            this.back()
          }, 1000)
        }
      })
    }
  }
}
</script>
<style scoped lang="less">
.page_wrapper {
  padding: 15px;
  padding-top: 40px;
  font-size: 14px;

  .fix_head {
    background: #ED5D43;
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    height: 40px;
    line-height: 40px;
    color: #fff;
    padding: 0 10px;
    text-align: center;
    z-index: 10;

    a {
      color: #fff;
      text-decoration: none;
      position: absolute;
      height: 100%;
      width: 60px;
      left: 0px;
    }
  }
}


.claim_apply {
  font-size: 12px;
  padding: 15px;
}

/deep/ .van-field__control {
  text-align: right !important;
}

.title {
  // font-size: 16px;
  font-weight: 600;
  margin-top: 10px;
}

.div_btn {
  padding: 20px 10px;
  display: flex;
  justify-content: space-between;
}

.div_btn .query {
  background: #ED5D43;
  color: #fff;
  text-decoration: none;
  padding: 10px;
  border-radius: 4px;
  text-align: center;
  width: 60%;
  border: 0;
  margin: auto;
}

/deep/.van-cell__value {
  min-width: 175px;
}

.upload {
  width: 50%;
  border: 1px solid #EEEEEE;
  border-collapse: collapse;
}

.error_tip {
  color: #ED5D43;
  font-size: 10px;
  text-align: center;
}

.required {
  position: relative;
}

.required::before {
  content: '*';
  color: red;
  position: absolute;
  z-index: 10;
  left: 8px;
  top: 50%;
  transform: translateY(-50%);
}
</style>
